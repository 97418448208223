import { lazy } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

// Services
import { getStoredToken, getStoredUser } from 'services/storage';
import { getBackUrl } from 'services/helpers';

// Components
import PageNotFound from 'components/page-not-found';

// Views
import AuthenticatedLayout from 'views/authenticated';
import InternshipRoutes from './internship-routes';
import QuestionnaireRoutes from './questionnaire-routes';
import LearnerRoutes from './learner-routes';
import ManagementRoutes from './management-routes';

/* * *
* Lazy Imports
*/

const Dashboard = lazy(() => import('../../views/authenticated/dashboard'));
const Profile = lazy(() => import('../../views/authenticated/profile'));
const AppEvents = lazy(() => import('../../views/authenticated/app-events'));
const DeclareAbsence = lazy(() => import('../../views/authenticated/declare-absence'));

/* * *
* Require Authentication
*/

const RequireAuth = ({ children } : { children: JSX.Element }) => {
    
    const token = getStoredToken();
    const location = useLocation();

    if (token) {
        return children;
    }
    return <Navigate to="/" state={{ from: location }} replace />;
};

/* * *
* Learner Restriction
*/

export const LearnerRestriction = ({ children } : { children: JSX.Element }) => {
    
    const storedUser = getStoredUser();

    if (storedUser?.role?.id && Number(storedUser.role.id) < 5) {
        return children;
    }
    return <Navigate to="/app" replace />;
};

/* * *
* Require Permission
*/

export const RequirePerm = ({ children, perm } : { children: JSX.Element, perm: boolean }) => {

    const location = useLocation();

    if (perm) {
        return children;
    }
    return <Navigate to={getBackUrl(location.pathname)} replace />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ PRIVATE ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PrivateRoutes() {
    return (
        <Routes>
            <Route element={<RequireAuth><AuthenticatedLayout /></RequireAuth>}>
                <Route index element={<Dashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="events" element={<AppEvents />} />
                <Route path="declare-absence" element={<DeclareAbsence /> } />
                <Route path="internships/*" element={<InternshipRoutes />} />
                <Route path="questionnaires/*" element={<QuestionnaireRoutes />} />
                <Route path="learners/*" element={<LearnerRoutes />} />
                <Route path="management/*" element={<LearnerRestriction><ManagementRoutes /></LearnerRestriction>} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}
