import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';

// MUI
import { frFR, deDE, enUS } from '@mui/x-date-pickers';

// Services
import { IDynamicLabel, IRoadmap, IAtypicalRepository, IAtypicalItem } from './interfaces';
import { EPostInternshipResult, ERating, EStamp, ENotificationType, EGender } from './enums';
import { getStoredUser } from './storage';

// Components
import { isValidPhoneNumber } from 'components/react-international-phone';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- VERSION NAME ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getVersionName = () => {

    switch (window.origin) {

        case 'http://localhost:3000':
        case 'http://localhost:3001':
            return 'dev';

        case 'https://beta.happinco.app':
            return 'beta';

        case 'https://demo.happinco.app':
            return 'demo';
            
        default:
            return '';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOCALES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocales = () => [
    {
        value: 'fr',
        label: i18next.t('locale.fr'),
        notTranslated: 'Français',
    },
    {
        value: 'fr-FALC',
        label: i18next.t('locale.fr-falc'),
        notTranslated: 'Français - Facile à Lire et à Comprendre (FALC)',
    },
    {
        value: 'en',
        label: i18next.t('locale.en'),
        notTranslated: 'English',
    },
    {
        value: 'de',
        label: i18next.t('locale.de'),
        notTranslated: 'Deutsch',
    },
    {
        value: 'sl',
        label: i18next.t('locale.sl'),
        notTranslated: 'Slovenščina',
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- HANDLE LOCALE MANAGEMENT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const handleLocaleManagement = (locale: string | undefined) => {
    if (locale) {
        localStorage.setItem('locale', locale);
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
        document.documentElement.setAttribute('lang', locale.substring(0, 2));
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET ADAPTER LOCALE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAdapterLocale = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return 'fr';

        case 'de':
            return 'de';

        default:
            return 'en';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET LOCALE TEXT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocaleText = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return frFR.components.MuiLocalizationProvider.defaultProps.localeText;

        case 'de':
            return deDE.components.MuiLocalizationProvider.defaultProps.localeText;

        default:
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- USER HAS ACCESS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const userHasAccess = (applicationName: 'zerobarrier' | 'happinco' | 'mealsAndBenefits') => {

    const applications = getStoredUser()?.company?.applications || [];

    return applications.some(app =>
        app.name === applicationName
        && (
            app.expiration == null
            || app.expiration >= dayjs().format('YYYY-MM-DD')
        )
    );
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- GET ERROR EXTENSIONS  ----------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getErrorExtensions = (error: any) => {
    let hasErrorExtension = false;
    let message = '';

    for (let err of error.graphQLErrors) {
        let item = '';

        if (err?.extensions?.validation && Object.values(err?.extensions?.validation)?.length > 0) {
            hasErrorExtension = true;

            Object.values(err.extensions.validation).forEach((value: any) => {
                value.forEach((v: any) => item += v);
            });
        }
        message += item;
    }

    if (hasErrorExtension) {
        return message;
    }
    return error?.message;
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- IS VALID EMAIL --------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const isValidEmail = (email: string) => {
    // eslint-disable-next-line
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return regex.test(email);
};

// --------------------------------------------------------------------------------- \\
// ---------------------------- GET EMAIL HELPER TEXT ------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const getEmailHelperText = (email: string, phoneNumber: string) => {
    if (email !== '' && !isValidEmail(email)) {
        return i18next.t('invalid_email');
    }
    if (email === '' && !isValidPhoneNumber(phoneNumber)) {
        return i18next.t('please_enter_mail_or_phone');
    }
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- GET BACK URL ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getBackUrl = (url: string) => {
    let index = url.lastIndexOf('/');
    if (index !== -1) {
        return url.slice(0, index);
    }
    return url;
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ GET FORMATTED DATE ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFormattedDate = (date: Date | string | undefined) => {
    if (date) {
        switch (i18next.language.substring(0, 2)) {
            case 'fr':
                return dayjs(date).format('DD/MM/YYYY');
    
            case 'en':
                return dayjs(date).format('MM/DD/YYYY');
    
            default:
                return date.toString();
        }
    }
    return date;
};

// --------------------------------------------------------------------------------- \\
// ---------------------------- GET FORMATTED DATE TIME ---------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFormattedDateTime = (date?: Date) => {
    if (date) {
        switch (i18next.language) {
            case 'fr':
                return dayjs(date).format('dddd DD MMMM YYYY à HH:mm');
    
            case 'en':
                return dayjs(date).format('MMMM dddd DD YYYY at HH:mm');
    
            default:
                return date.toString();
        }
    }
    return date;
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- GET ROLE LABEL -------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getRoleLabel = (roleId: string) => {

    switch (roleId) {

        case '1':
            return i18next.t('super_admin');

        case '2':
            return i18next.t('admin');

        case '3':
            return i18next.t('manager');

        case '4':
            return i18next.t('mediator_tutor');

        case '5':
            return i18next.t(getDynamicLabelKey('learner'));

        default:
            return i18next.t('none.1');
    }
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- GET GENDER LABEL ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getGenderLabel = (gender: EGender) => {
    
    switch (gender) {

        case EGender.UNKNOWN:
            return i18next.t('unspecified');

        default:
            return i18next.t(gender.toLowerCase());
    }
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- GET RATING LABEL ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getRatingLabel = (rating: ERating) => {

    switch (rating) {

        case ERating.VERY_SATISFIED:
            return i18next.t('very_satisfied');

        case ERating.SATISFIED:
            return i18next.t('satisfied');

        case ERating.DISSATISFIED:
            return i18next.t('dissatisfied');

        case ERating.VERY_DISSATISFIED:
            return i18next.t('very_dissatisfied');

        case ERating.DO_NOT_KNOW:
            return i18next.t('do_not_know');
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- GET GOALS RESULT LABEL ---------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getGoalsResultLabel = (result: EStamp) => {

    switch (result.toString()) {

        case EStamp.VALIDATED:
            return i18next.t('validated');

        case EStamp.PARTIALLY:
            return i18next.t('partially_validated');

        case EStamp.FAILED:
            return i18next.t('failed');
    }
};

// --------------------------------------------------------------------------------- \\
// ------------------------ GET POST INTERNSHIP RESULT LABEL ----------------------- \\
// --------------------------------------------------------------------------------- \\

export const getPostInternshipResultLabel = (result?: EPostInternshipResult) => {

    switch (result) {

        case EPostInternshipResult.NONE:
            return i18next.t('internship_result_none');

        case EPostInternshipResult.POSITIVE:
            return i18next.t('internship_result_positive');

        case EPostInternshipResult.TRANSITION:
            return i18next.t('internship_result_transition');

        case EPostInternshipResult.SUSTAINABLE:
            return i18next.t('internship_result_sustainable');

        case EPostInternshipResult.SUSTAINABLE_NO_END:
            return i18next.t('internship_result_sustainable_no_end');

        case EPostInternshipResult.PROFESSIONAL_PROJECT_VALIDATED:
            return i18next.t('professional_project_validated');

        default:
            return i18next.t('none.1');
    }
};

// --------------------------------------------------------------------------------- \\
// -------------------------- GET NOTIFICATION TYPE LABEL -------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getNotificationTypeLabel = (notificationType: ENotificationType) => {

    switch (notificationType) {

        case ENotificationType.ALL:
            return i18next.t('all.4');

        case ENotificationType.NONE:
            return i18next.t('none.2');

        default:
            return i18next.t(notificationType.toLowerCase());
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- CONVERT IMG TO BASE 64 ---------------------------- \\
// --------------------------------------------------------------------------------- \\

export const convertImgToBase64URL = (url: string) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
            var canvas = document.createElement('CANVAS') as any;
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            resolve({ imgData: canvas.toDataURL('image/jpeg'), width: canvas.width, height: canvas.height });
            canvas = null; 
        };

        img.onerror = () => {
            reject('Image not found.');
        };

        img.src = url;
    });
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ ASPECT RATIO JSPDF ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { resizedWidth: (srcWidth * ratio), resizedHeight: (srcHeight * ratio) };
};

// --------------------------------------------------------------------------------- \\
// ---------------------------- GET DYNAMIC LABEL KEY ------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const getDynamicLabelKey = (key: string) => {
    let user = getStoredUser();

    let dynamicLabel : IDynamicLabel | undefined = user?.company?.dynamicLabels?.find(l => l.originalKey === key);
    if (dynamicLabel) {
        return dynamicLabel.replacementKey;
    }
    return key;
};

// --------------------------------------------------------------------------------- \\
// ---------------------------- GET APPLICATION NAME ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getApplicationName = (name: string) => {

    switch (name) {

        case 'zerobarrier':
            return 'Zero Barrier';

        case 'happinco':
            return 'HappIn\'Co';

        case 'mealsAndBenefits':
            return 'Repas et Bienfaits';

        default:
            return name;
    }
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ GET ROADMAP TITLE -------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getRoadmapTitle = (roadmap: IRoadmap) => {

    let title = `${roadmap.customRepository?.name} ${i18next.t('at')} ${roadmap.company?.name}`;

    if (roadmap.endDate) {
        title += ` (${getFormattedDate(roadmap.startDate)} - ${getFormattedDate(roadmap.endDate)})`;
    }
    else {
        title += ` (${getFormattedDate(roadmap.startDate)})`;
    }

    return title;
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ GET FULL ADDRESS --------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFullAddress = (object: any) => {

    let array = [];

    if (object?.address) {
        array.push(object.address);
    }
    if (object?.postalCode) {
        array.push(object.postalCode);
    }
    if (object?.city) {
        array.push(object.city);
    }
    if (object?.country) {
        array.push(object.country?.name);
    }

    return array.join(' ');
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------- GET ATYPICAL ITEMS FROM ATYPICAL REPOSITORY -------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAtypicalItemsFromAtypicalRepository = (atypicalRepository: IAtypicalRepository) => {

    let atypicalItems : IAtypicalItem[] = [];

    if (
        atypicalRepository.atypicalLevels
        && atypicalRepository.atypicalLevels.length > 0
    ) {
        atypicalRepository.atypicalLevels.forEach(atypicalLevel => {
            atypicalItems = atypicalItems.concat(atypicalLevel.atypicalItems);
        });
    }

    return atypicalItems;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CAPITALIZE FIRST LETTER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GET FORMATTED TIME ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFormattedTime = (time: string) => {
    return time.substring(0, 5).replace(':', 'h');
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ COMPARE TIMES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const compareTimes = (time1: Dayjs, comparator: '<' | '>' | '<=' | '>=', time2: Dayjs) => {

    switch (comparator) {

        case '<':
            return dayjs(time1).format('HH:mm') < dayjs(time2).format('HH:mm');

        case '>':
            return dayjs(time1).format('HH:mm') > dayjs(time2).format('HH:mm');

        case '<=':
            return dayjs(time1).format('HH:mm') <= dayjs(time2).format('HH:mm');

        case '>=':
            return dayjs(time1).format('HH:mm') >= dayjs(time2).format('HH:mm');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- COMPARE DATES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const compareDates = (date1: Dayjs | null, date2: Dayjs | null, operator: '>' | '<' | '=') => {

    if (date1 && date2) {

        switch (operator) {

            case '>':
                return dayjs(date1).format('YYYY-MM-DD') > dayjs(date2).format('YYYY-MM-DD');

            case '<':
                return dayjs(date1).format('YYYY-MM-DD') < dayjs(date2).format('YYYY-MM-DD');

            case '=':
                return dayjs(date1).format('YYYY-MM-DD') === dayjs(date2).format('YYYY-MM-DD');
        }
    }
    return false;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- TRUNCATE LABEL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const truncateLabel = (label: string, index: number) => {
    if (label.length > index) {
        return `${label.substring(0, index).trimEnd()}...`;
    }
    return label;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SET DOCUMENT TITLE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const setDocumentTitle = (title: string) => {
    document.title = `${truncateLabel(title, 48)} | Happ'In Co`;
};
