import { useEffect, useCallback } from 'react';

// MUI
import useMediaQuery from '@mui/material/useMediaQuery';

// Services
import { setDocumentTitle } from 'services/helpers';

// --------------------------------------------------------------------------------- \\
// ------------------------------- DOCUMENT TITLE ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useDocumentTitle = (title: string) => {
    useEffect(() => {
        setDocumentTitle(title);
    }, [title]);
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- MEDIA QUERIES ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useMediaQueries = () => {
    return {
        mediaXL: useMediaQuery('(min-width:1536px)'),
        mediaLG: useMediaQuery('(min-width:1200px)'),
        mediaMD: useMediaQuery('(min-width:900px)'),
        mediaSM: useMediaQuery('(min-width:600px)'),
        mediaXS: useMediaQuery('(min-width:0px)'),
        mediaDesktop: useMediaQuery('(pointer:fine)'),
    };
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- SCROLL DATA ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useScrollData = ({
    state,
    setState,
    dependency
}: ({
    state: any,
    setState: React.Dispatch<React.SetStateAction<any>>,
    dependency: any,
})) => {

    const onReachEnd = useCallback(() => {

        let contentLayoutScroll = document.getElementById('ContentLayoutScroll') as HTMLElement;
        let scrollTop = contentLayoutScroll.scrollTop;
        let scrollHeight = contentLayoutScroll.scrollHeight;
        let innerHeight = window.innerHeight;

        if ((state.page * state.first < state.total) && (scrollTop && scrollHeight) && (scrollTop + innerHeight >= (scrollHeight - 24))) {
            contentLayoutScroll.removeEventListener('scroll', onReachEnd);
            setState((prevState: any) => ({ ...prevState, loading: true, page: prevState.page += 1 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);

    useEffect(() => {

        let contentLayoutScroll = document.getElementById('ContentLayoutScroll') as HTMLElement;
        contentLayoutScroll.addEventListener('scroll', onReachEnd);

        return () => {
            contentLayoutScroll.removeEventListener('scroll', onReachEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);
};
