import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// Dayjs
import dayjs from 'dayjs';

// MUI
import { Drawer, Divider, List, ListItemButton } from '@mui/material';
import {
    Dashboard as DashboardIcon, Logout as LogoutIcon, People as PeopleIcon,
    Settings as SettingsIcon, AutoStories as AutoStoriesIcon,
} from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';
import { getPermissions, getStoredUser } from 'services/storage';
import { getDynamicLabelKey, getVersionName } from 'services/helpers';
import { EDestinationApp } from 'services/enums';
import { magentaMain } from 'services/constants';
import PackageJSON from '../../../package.json';

// Icons
import { InternshipIcon, QuestionnaireIcon } from 'icons';

// Components
import Logout from 'views/authenticated/logout';
import LoginCode from 'views/authenticated/login-code';

// Assets
import { ReactComponent as ZerobarrierIcon } from 'assets/images/zerobarrier-circle.svg';

// CSS
import './menu.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- MENU ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Menu({
    open,
    onClose,
} : {
    open: boolean,
    onClose: VoidFunction,
}) {

    const permissions = getPermissions();
    const storedUser = getStoredUser();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const [openLogout, setOpenLogout] = useState(false);

    const [state, setState] = useState({
        loginCodeDestination: EDestinationApp.ZEROBARRIER,
        loginCodeUrl: '',
        openLoginCode: false,
    });

    const iconProps : React.CSSProperties = {
        marginRight: 10,
        color: magentaMain,
    };

    const onClickLogout = () => {
        onClose();
        setOpenLogout(true);
    };

    const zerobarrierEnalbed = !! (
        storedUser?.company?.applications
        && storedUser.company.applications.some(a =>
            a.name === 'zerobarrier'
            && (a.expiration == null || a.expiration > dayjs().format('YYYY-MM-DD'))
        )
    );

    return (
        <>
            <Drawer
                anchor={mediaMD ? 'left' : 'top'}
                open={open}
                onClose={onClose}
            >
                <List style={{ minWidth: 280 }}>
                    {/* -------------------- DASHBOARD -------------------- */}
                    <NavLink
                        to="/app"
                        end
                        className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                    >
                        <ListItemButton onClick={onClose}>
                            <DashboardIcon
                                style={iconProps}
                            />
                            <span>
                                {t('dashboard')}
                            </span>
                        </ListItemButton>
                    </NavLink>
                    {/* -------------------- INTERNSHIPS -------------------- */}
                    {permissions?.includes('internships.viewAny') && (
                        <NavLink
                            to="/app/internships"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <InternshipIcon
                                    style={iconProps}
                                />
                                <span>
                                    {t('internships')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- QUESTIONNAIRES -------------------- */}
                    <NavLink
                        to="/app/questionnaires"
                        className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                    >
                        <ListItemButton onClick={onClose}>
                            <QuestionnaireIcon
                                style={iconProps}
                            />
                            <span>
                                {t('questionnaires')}
                            </span>
                        </ListItemButton>
                    </NavLink>
                    {(
                        storedUser?.role?.id
                        && Number(storedUser.role.id) < 5
                    ) && (
                        <>
                            {/* -------------------- LEARNERS -------------------- */}
                            <NavLink
                                to="/app/learners"
                                className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                            >
                                <ListItemButton onClick={onClose}>
                                    <PeopleIcon
                                        style={iconProps}
                                    />
                                    <span>
                                        {t(getDynamicLabelKey('learner') + 's')}
                                    </span>
                                </ListItemButton>
                            </NavLink>
                            {/* -------------------- MANAGEMENT -------------------- */}
                            <NavLink
                                to="/app/management"
                                className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                            >
                                <ListItemButton onClick={onClose}>
                                    <SettingsIcon
                                        style={iconProps}
                                    />
                                    <span>
                                        {t('management')}
                                    </span>
                                </ListItemButton>
                            </NavLink>
                        </>
                    )}
                    {/* -------------------- LOGOUT -------------------- */}
                    <ListItemButton onClick={onClickLogout}>
                        <LogoutIcon style={iconProps} />
                        <span>
                            {t('logout')}
                        </span>
                    </ListItemButton>
                </List>
                <Divider />
                {/* -------------------- USER GUIDE -------------------- */}
                <List>
                    <ListItemButton
                        onClick={() => setState(prevState => ({
                            ...prevState,
                            openLoginCode: true,
                            loginCodeDestination: EDestinationApp.USER_GUIDE,
                            loginCodeUrl: '&applicationId=2',
                        }))}
                    >
                        <AutoStoriesIcon
                            sx={{ fontSize: 26, color: 'gray' }}
                        />
                        <span style={{ marginLeft: 10 }}>
                            {t('user_guide')}
                        </span>
                    </ListItemButton>
                </List>
                <Divider />
                {/* -------------------- ZEROBARRIER -------------------- */}
                {zerobarrierEnalbed && (
                    <>
                        <List>
                            <ListItemButton
                                onClick={() => setState(prevState => ({
                                    ...prevState,
                                    openLoginCode: true,
                                    loginCodeDestination: EDestinationApp.ZEROBARRIER,
                                    loginCodeUrl: '',
                                }))}
                            >
                                <ZerobarrierIcon
                                    style={{ width: 24, height: 24, display: 'block' }}
                                />
                                <span style={{ marginLeft: 10 }}>
                                    ZERO BARRIER
                                </span>
                            </ListItemButton>
                        </List>
                        <Divider />
                    </>
                )}
                <div
                    style={{ flex: 1 }}
                />
                {/* -------------------- VERSION -------------------- */}
                <div className="Menu-version">
                    v{PackageJSON.version} {getVersionName()}
                </div>
            </Drawer>
            {/* -------------------- LOGOUT DIALOG -------------------- */}
            <Logout
                open={openLogout}
                onClose={() => setOpenLogout(false)}
            />
            {/* -------------------- LOGIN WITH CODE -------------------- */}
            {state.openLoginCode && (
                <LoginCode
                    url={state.loginCodeUrl}
                    to={state.loginCodeDestination}
                    onClose={() => setState(prevState => ({ ...prevState, openLoginCode: false }))}
                />
            )}
        </>
    );
}
