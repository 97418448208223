import { SvgIconProps } from '@mui/material';
import { CheckBoxOutlined as CheckBoxOutlinedIcon, Badge as BadgeIcon, QuestionAnswer as QuestionAnswerIcon } from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- INTERNSHIP ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const InternshipIcon = (props: SvgIconProps) => {
    return <BadgeIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- QUESTIONNAIRE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const QuestionnaireIcon = (props: SvgIconProps) => {
    return <QuestionAnswerIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- QUESTION --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const QuestionIcon = (props: SvgIconProps) => {
    return <QuestionAnswerIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECTED REPOSITORY ITEM ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const SelectedRepositoryItemIcon = (props: SvgIconProps) => {
    return <CheckBoxOutlinedIcon {...props} />;
};
